import React, { Component } from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

//images
import AboutPageImage from "../components/images/AboutPageImage"
//mui
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing.unit,
    width: "50%",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  Typography: { marginBottom: "20px" },
  spaceWaster: {
    height: "10vh",
  },
})

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = { name: "", email: "", message: "" }
  }

  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error))

    e.preventDefault()
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, email, phone, multiline } = this.state
    const { classes } = this.props
    return (
      <Layout>
        <SEO
          title="Contact"
          keywords={[
            `adc`,
            `American Dream Construction`,
            `contact`,
            `email`,
            `phone number`,
            `fax`,
            `multiline`,
          ]}
        />

        <div className="container-body">
          <div className="container-centered">
            {" "}
            <Typography variant="h5" gutterBottom className="container-margin">
              {" "}
              SERVING CLIENTS THROUGHOUT ALASKA
            </Typography>
          </div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <div>
                <Grid
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  <Typography
                    className={classes.Typography}
                    variant="h4"
                    gutterBottom
                    style={{ fontSize: "26px" }}
                  >
                    CONTACT ADC
                  </Typography>

                  <Typography variant="body" className={classes.Typography}>
                    For all inquiries, please use this form and someone will be
                    in touch shortly. You can also reach us at one of the
                    following:
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    Email:
                  </Typography>

                  <Typography className={classes.Typography}>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      info@adcalaska.com
                    </a>{" "}
                  </Typography>

                  <Typography variant="h6">Office:</Typography>

                  <Typography>
                    <a
                      href="tel:9073558610"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      (907) 355-8610{" "}
                    </a>
                  </Typography>
                  <br />

                  <Typography variant="h6">New Project Development:</Typography>

                  <Typography className={classes.Typography}>
                    <a
                      href="tel:9073558640"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      (907) 355-8640{" "}
                    </a>
                  </Typography>

                  <Typography variant="h6">Fax:</Typography>

                  <Typography className={classes.Typography}>
                    (907) 357-8630
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontSize: "26px" }}
              >
                SEND A MESSAGE
              </Typography>

              <form
                onSubmit={this.handleSubmit}
                name="contact"
                method="post"
                action="/success"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />

                <TextField
                  id="outlined-name"
                  label="Name"
                  type="name"
                  name="name"
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: "80%" }}
                />

                <TextField
                  id="outlined-email-input"
                  label="Email"
                  className={classes.textField}
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="outlined-phone-input"
                  label="Phone Number"
                  className={classes.textField}
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={this.handleChange}
                  autoComplete="phone number"
                  margin="normal"
                  variant="outlined"
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Get in touch"
                  multiline
                  fullWidth
                  label="Get in touch"
                  name="multiline"
                  type="text"
                  value={multiline}
                  onChange={this.handleChange}
                  rows="4"
                  width="100%"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  {" "}
                  Send
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ContactPage)
